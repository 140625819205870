<template>
  <div class="home-container">
    <header class="header">
      <div class="logo">NGHIỆP ĐOÀN LAO ĐỘNG</div>
      <div class="search-bar">
        <input type="text" placeholder="Tìm kiếm" />
      </div>
    </header>
    <div class="content">
    <link href="https://nghiepdoanlaodongvn.com/css/app.99f2331e.css" rel="stylesheet">

<div data-v-9c516a62="" class="sc-q9qagu-15 jbsSbC">
    <div data-v-9c516a62="" class="sc-11qpg5t-0 sc-z4q3vq-3 gXrCuJ hMZfLG">
        <div data-v-9c516a62="" class="sc-z4q3vq-4 guedfL">
            <div data-v-9c516a62="" dir="ltr" class="slick-slider slick-initialized">
                <div data-v-9c516a62="" class="slick-list">
                    <div data-v-9c516a62="" class="slick-track" style="width: 828px; opacity: 1; transform: translate3d(0px, 0px, 0px);">
                        <div data-v-9c516a62="" data-index="0" tabindex="-1" aria-hidden="false" class="slick-slide slick-active slick-current" style="outline: none;">
                            <div data-v-9c516a62="">
                                <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                    <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks">
                                        <div data-v-9c516a62="" style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
                                            <img data-v-9c516a62="" alt="Cần tuyển kỹ thuật viên đơn nông nghiệp - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="https://i.imgur.com/dkY37eQ.gif" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                                            <noscript data-v-9c516a62="">
                                                <img data-v-9c516a62="" alt="Cần tuyển kỹ thuật viên đơn nông nghiệp - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw" src="https://i.imgur.com/dkY37eQ.gif" decoding="async" data-nimg="fill" loading="lazy" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                                            </noscript>
                                        </div>
                                        <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span data-v-9c516a62="">Nổi bật</span></div>
                                        <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span>
                                        <span data-v-9c516a62="" class="sc-1vo1n72-4 fjImPV">2</span>
                                    </div>
                                    <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd">
                                        <a data-v-9c516a62="" href="javascript:void(0);">
                                            <span data-v-9c516a62="" class="title">Cần tuyển kỹ thuật viên đơn nông nghiệp - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span>
                                        </a>
                                        <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                            <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                        </ul>
                                        <span data-v-9c516a62="" class="price">Thỏa thuận</span>
                                    </div>
                                    <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                        <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                            <span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span>
                                            <span data-v-9c516a62="">Hôm nay</span>
                                        </li>
                                        <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                            <span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span>
                                            <span data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div data-v-9c516a62="" data-index="1" tabindex="-1" aria-hidden="false" class="slick-slide slick-active" style="outline: none;">
                            <div data-v-9c516a62="">
                                <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                    <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks">
                                        <div data-v-9c516a62="" style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
                                            <img data-v-9c516a62="" alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" fill="true" sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                                            <noscript data-v-9c516a62="">
                                                <img data-v-9c516a62="" alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" fill="true" sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg#w=1024" decoding="async" data-nimg="fill" loading="lazy" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                                            </noscript>
                                        </div>
                                        <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span data-v-9c516a62="">Nổi bật</span></div>
                                        <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span>
                                        <span data-v-9c516a62="" class="sc-1vo1n72-4 fjImPV">2</span>
                                    </div>
                                    <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd">
                                        <a data-v-9c516a62="" href="javascript:void(0);">
                                            <span data-v-9c516a62="" class="title">CẦN TUYỂN: 10 NAM LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span>
                                        </a>
                                        <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                            <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                        </ul>
                                        <span data-v-9c516a62="" class="price">Thỏa thuận</span>
                                    </div>
                                    <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                        <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                            <span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span>
                                            <span data-v-9c516a62="">Hôm nay</span>
                                        </li>
                                        <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                            <span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span>
                                            <span data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    <div data-v-9c516a62="" data-index="2" tabindex="-1" aria-hidden="false" class="slick-slide slick-active" style="outline: none;">
                    <div data-v-9c516a62="">
                    <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                    <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks">
                    <div data-v-9c516a62="" style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
                    <img data-v-9c516a62="" alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." fill="true" sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    <noscript data-v-9c516a62="">
                    <img data-v-9c516a62="" alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." fill="true" sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg#w=1024" decoding="async" data-nimg="fill" loading="lazy" class="thumb-l" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    </noscript>
                    </div>
                    <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC">
                    <span data-v-9c516a62="">Nổi bật</span></div>
                    <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
                    <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62="" href="javascript:void(0);">
                    <span data-v-9c516a62="" class="title">Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
                    <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi"><li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li></ul>
                    <span data-v-9c516a62="" class="price">Từ 10.000.000 đ/tháng</span></div><ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                    <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span>
                    <span data-v-9c516a62="">Hôm nay</span></li><li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                    <span data-v-9c516a62="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span data-v-9c516a62="">Quận Nam Từ Liêm, Hà Nội</span>
                    </li></ul></div></div></div>


                    </div>
                </div>
                <ul data-v-9c516a62="" class="slick-dots">
                    <li data-v-9c516a62="" class="slick-active"><button data-v-9c516a62="" type="button">1</button></li>
                    <li data-v-9c516a62="" class=""><button data-v-9c516a62="" type="button">2</button></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<style>
    .slick-slide {
        opacity: 0.6;
        transition: opacity 0.3s;
    }
    .slick-active {
        opacity: 1 !important;
    }
    .slick-track {
        display: flex !important;
    }
</style>

<div data-v-9c516a62="" class="sc-f5e146-0 cOcyJJ"></div>


<div data-v-9c516a62="" class="sc-q9qagu-4 enWxAW">
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq">
                <div data-v-9c516a62=""
                     style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
                    <img data-v-9c516a62="" alt="TUYỂN NAM PHỤ BẾP CHO BẾP TRƯỞNG TẠI BẾP ĂN TRƯỜNG HỌC QUẬN ĐỐNG ĐA."
                         fill="true" sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/8e9ba624901c42d285e8cc1809f9775e.jpg#w=1024"
                         decoding="async" data-nimg="fill" class="thumb-l"
                         style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    <noscript data-v-9c516a62=""><img data-v-9c516a62=""
                                                      alt="TUYỂN NAM PHỤ BẾP CHO BẾP TRƯỞNG TẠI BẾP ĂN TRƯỜNG HỌC QUẬN ĐỐNG ĐA."
                                                      fill="true" sizes="100vw"
                                                      src="https://nghiepdoanlaodongvn.com/img/sy/8e9ba624901c42d285e8cc1809f9775e.jpg#w=1024"
                                                      decoding="async" data-nimg="fill" loading="lazy" class="thumb-l"
                                                      style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    </noscript>
                </div>
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">TUYỂN NAM PHỤ BẾP CHO BẾP TRƯỞNG TẠI BẾP ĂN TRƯỜNG HỌC QUẬN ĐỐNG ĐA.</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Từ 5.500.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Đống Đa, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq">
                <div data-v-9c516a62=""
                     style="display: block; overflow: hidden; position: absolute; inset: 0px; box-sizing: border-box; margin: 0px;">
                    <img data-v-9c516a62="" alt="Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van" fill="true"
                         sizes="100vw" src="https://nghiepdoanlaodongvn.com/img/sy/5d4ec8c3e79b4bb481a203bd4827c192.jpg#w=1024" decoding="async"
                         data-nimg="fill" class="thumb-l"
                         style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    <noscript data-v-9c516a62=""><img data-v-9c516a62=""
                                                      alt="Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van" fill="true"
                                                      sizes="100vw"
                                                      src="https://nghiepdoanlaodongvn.com/img/sy/5d4ec8c3e79b4bb481a203bd4827c192.jpg#w=1024"
                                                      decoding="async" data-nimg="fill" loading="lazy" class="thumb-l"
                                                      style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;">
                    </noscript>
                </div>
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">9.500.000 - 12.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/03435067b0174cf1be285f52d8522b2d.jpg"
                                                                    alt="TUYỂN 01 NAM LÁI XE NÂNG – 01 CÁN BỘ QUẢN LÝ SẢN XUẤT"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/03435067b0174cf1be285f52d8522b2d.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">TUYỂN 01 NAM LÁI XE NÂNG – 01 CÁN BỘ QUẢN LÝ SẢN XUẤT</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">8.000.000 - 12.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Huyện Thường Tín, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/26210c36eb2248b0a1cd08c63b46d942.jpg"
                                                                    alt="TUYỂN 03 LAO ĐỘNG PHỔ THÔNG + NHÂN VIÊN KỸ THUẬT"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/26210c36eb2248b0a1cd08c63b46d942.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">TUYỂN 03 LAO ĐỘNG PHỔ THÔNG + NHÂN VIÊN KỸ THUẬT</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">8.000.000 - 12.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Huyện Thanh Trì, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/09133f8eb067428b982f152a66e40521.jpg"
                                                                    alt="Tuyển nhân viên pha chế, chạy bàn, đầu bếp Âu cho quán cafe"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/09133f8eb067428b982f152a66e40521.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển nhân viên pha chế, chạy bàn, đầu bếp Âu cho quán cafe</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Ba Đình, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62="" data-src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    alt="Tuyển nhân viên nữ tẩm quất giác hơi lành mạnh, từ 18-35 tuổi"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển nhân viên nữ tẩm quất giác hơi lành mạnh, từ 18-35 tuổi</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Tây Hồ, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/66774a8b89c24e18857dd948c434770d.jpg"
                                                                    alt="Cần tuyển nhân sự bếp, bàn, tạp vụ"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/66774a8b89c24e18857dd948c434770d.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Cần tuyển nhân sự bếp, bàn, tạp vụ</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Từ 34.000 đ</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62="" data-src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    alt="Cần tuyển 2 lái xe 7 chỗ chạy du lịch và đưa đón văn phòng "
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Cần tuyển 2 lái xe 7 chỗ chạy du lịch và đưa đón văn phòng </span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/5af10832bdd24c53a77dc0e8f3b1be05.jpg"
                                                                    alt="TUYỂN GẤP LÁI XE ĐI LÀM NGAY"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/5af10832bdd24c53a77dc0e8f3b1be05.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">TUYỂN GẤP LÁI XE ĐI LÀM NGAY</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Từ 8.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Tây Hồ, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg"
                                                                    alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span data-v-9c516a62=""
                                                                                 class="sc-1vo1n72-4 fjImPV">2</span>
            </div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <section data-v-9c516a62="">
        <div data-v-9c516a62="" class="sc-f5e146-0 fpAWLv"></div>
        <div data-v-9c516a62="" class="sc-q9qagu-15 jbsSbC">
            <div data-v-9c516a62="" class="sc-11qpg5t-0 sc-z4q3vq-3 gXrCuJ hMZfLG">
                <div data-v-9c516a62="" class="sc-z4q3vq-4 guedfL">
                    <div data-v-9c516a62="" dir="ltr" class="slick-slider slick-initialized">
                        <div data-v-9c516a62="" class="slick-list">
                            <div data-v-9c516a62="" class="slick-track"
                                 style="width: 828px; opacity: 1; transform: translate3d(0px, 0px, 0px);">
                                <div data-v-9c516a62="" data-index="0" tabindex="-1" aria-hidden="false"
                                     class="slick-slide slick-active slick-current" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                            <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks"><img data-v-9c516a62=""
                                                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/5af10832bdd24c53a77dc0e8f3b1be05.jpg"
                                                                                                    alt="TUYỂN GẤP LÁI XE ĐI LÀM NGAY"
                                                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/5af10832bdd24c53a77dc0e8f3b1be05.jpg"
                                                                                                    class="thumb-l ls-is-cached lazyloaded">
                                                <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span
                                                        data-v-9c516a62="">Nổi bật</span></div>
                                                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
                                            <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62=""
                                                                                                  href="javascript:void(0);"><span
                                                    data-v-9c516a62=""
                                                    class="title">TUYỂN GẤP LÁI XE ĐI LÀM NGAY</span></a>
                                                <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                    <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                                </ul>
                                                <span data-v-9c516a62="" class="price">Từ 8.000.000 đ/tháng</span></div>
                                            <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                                                        data-v-9c516a62="">Hôm nay</span></li>
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                                                        data-v-9c516a62="">Quận Tây Hồ, Hà Nội</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-9c516a62="" data-index="1" tabindex="-1" aria-hidden="false"
                                     class="slick-slide slick-active" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                            <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks"><img data-v-9c516a62=""
                                                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/cd73b959061d4e89821d3b90e889982a.jpg"
                                                                                                    alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM"
                                                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/cd73b959061d4e89821d3b90e889982a.jpg"
                                                                                                    class="thumb-l ls-is-cached lazyloaded">
                                                <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span
                                                        data-v-9c516a62="">Nổi bật</span></div>
                                                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span
                                                        data-v-9c516a62="" class="sc-1vo1n72-4 fjImPV">2</span></div>
                                            <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62=""
                                                                                                  href="javascript:void(0);"><span
                                                    data-v-9c516a62="" class="title">CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM</span></a>
                                                <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                    <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                                </ul>
                                                <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                                            <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                                                        data-v-9c516a62="">Hôm nay</span></li>
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                                                        data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-9c516a62="" data-index="2" tabindex="-1" aria-hidden="false"
                                     class="slick-slide slick-active" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                            <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks"><img data-v-9c516a62=""
                                                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                                                    alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm"
                                                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                                                    class="thumb-l ls-is-cached lazyloaded">
                                                <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span
                                                        data-v-9c516a62="">Nổi bật</span></div>
                                                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
                                            <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62=""
                                                                                                  href="javascript:void(0);"><span
                                                    data-v-9c516a62="" class="title">Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm</span></a>
                                                <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                    <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                                </ul>
                                                <span data-v-9c516a62=""
                                                      class="price">5.000.000 - 6.500.000 đ/tháng</span></div>
                                            <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                                                        data-v-9c516a62="">Hôm nay</span></li>
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                                                        data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-9c516a62="" data-index="3" tabindex="-1" aria-hidden="true"
                                     class="slick-slide" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                            <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks"><img data-v-9c516a62=""
                                                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg"
                                                                                                    alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng."
                                                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg"
                                                                                                    class="thumb-l ls-is-cached lazyloaded">
                                                <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span
                                                        data-v-9c516a62="">Nổi bật</span></div>
                                                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
                                            <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62=""
                                                                                                  href="javascript:void(0);"><span
                                                    data-v-9c516a62="" class="title">Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
                                                <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                    <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                                </ul>
                                                <span data-v-9c516a62="" class="price">Từ 10.000.000 đ/tháng</span>
                                            </div>
                                            <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                                                        data-v-9c516a62="">Hôm nay</span></li>
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                                                        data-v-9c516a62="">Quận Nam Từ Liêm, Hà Nội</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-9c516a62="" data-index="4" tabindex="-1" aria-hidden="true"
                                     class="slick-slide" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-5 eQLvys">
                                            <div data-v-9c516a62="" class="sc-z4q3vq-6 bgopks"><img data-v-9c516a62=""
                                                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg"
                                                                                                    alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI"
                                                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg"
                                                                                                    class="thumb-l ls-is-cached lazyloaded">
                                                <div data-v-9c516a62="" class="sc-1vo1n72-1 cZhfaC"><span
                                                        data-v-9c516a62="">Nổi bật</span></div>
                                                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span
                                                        data-v-9c516a62="" class="sc-1vo1n72-4 fjImPV">2</span></div>
                                            <div data-v-9c516a62="" class="sc-z4q3vq-7 dpDCLd"><a data-v-9c516a62=""
                                                                                                  href="javascript:void(0);"><span
                                                    data-v-9c516a62="" class="title">CẦN TUYỂN: 10 NAM LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span></a>
                                                <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                    <li data-v-9c516a62="" class="sc-z4q3vq-12 biTXqW"></li>
                                                </ul>
                                                <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                                            <ul data-v-9c516a62="" class="sc-z4q3vq-8 lpuxSi">
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                                                        data-v-9c516a62="">Hôm nay</span></li>
                                                <li data-v-9c516a62="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
                                                    <span data-v-9c516a62=""
                                                          class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                                                        data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-9c516a62="" data-index="5" tabindex="-1" aria-hidden="true"
                                     class="slick-slide" style="outline: none;">
                                    <div data-v-9c516a62="">
                                        <div data-v-9c516a62="" class="sc-z4q3vq-1 kOWYhT"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-z4q3vq-2 fpHzhT dLYkG"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-v-9c516a62="" class="sc-f5e146-0 fpAWLv"></div>
    </section>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg"
                                                                    alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng."
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/8fe2a51ea1244e768d593027a2988840.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Từ 10.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Nam Từ Liêm, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62="" data-src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/category-2.png"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 gCoOOb"><span data-v-9c516a62="">VIP S</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">5.000.000 - 6.500.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/8e0c3c899f7c44f7853d250747ad0ec9.jpg"
                                                                    alt="TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : "
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/8e0c3c899f7c44f7853d250747ad0ec9.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span data-v-9c516a62=""
                                                                                 class="sc-1vo1n72-4 fjImPV">3</span>
            </div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : </span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">6.840.000 - 10.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/2329291cd6aa4981a48a54037fd95594.jpg"
                                                                    alt=" tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/2329291cd6aa4981a48a54037fd95594.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62=""> tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">10.000.000 - 12.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/b5ff8195a1ac4add93388a5d6001ab37.jpg"
                                                                    alt="Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo."
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/b5ff8195a1ac4add93388a5d6001ab37.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo.</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Cầu Giấy, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg"
                                                                    alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/5e608f5adece454ebfe371874e129589.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span data-v-9c516a62=""
                                                                                 class="sc-1vo1n72-4 fjImPV">2</span>
            </div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">CẦN TUYỂN: 10 NAM LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/cd73b959061d4e89821d3b90e889982a.jpg"
                                                                    alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/cd73b959061d4e89821d3b90e889982a.jpg"
                                                                    class="thumb-l ls-is-cached lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span><span data-v-9c516a62=""
                                                                                 class="sc-1vo1n72-4 fjImPV">2</span>
            </div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/e7dabb0252214661b987c004d4fbdf8b.jpg"
                                                                    alt="Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/e7dabb0252214661b987c004d4fbdf8b.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Từ 10.000.000 đ/tháng</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Long Biên, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.com/img/sy/d2b168e9a2a9480098f0fad25fde8789.jpg"
                                                                    alt="Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN"
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/d2b168e9a2a9480098f0fad25fde8789.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Huyện Thanh Trì, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div data-v-9c516a62="" class="sc-q9qagu-5 kNNcTy">
        <div data-v-9c516a62="" class="sc-q9qagu-3 bsQfQa">
            <div data-v-9c516a62="" class="sc-q9qagu-7 kqqkWq"><img data-v-9c516a62=""
                                                                    data-src="https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/29c38ddca68f483b844027998f1bf7a7.jpg"
                                                                    alt="Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất..."
                                                                    src="https://nghiepdoanlaodongvn.com/img/sy/29c38ddca68f483b844027998f1bf7a7.jpg"
                                                                    class="thumb-l lazyloaded">
                <div data-v-9c516a62="" class="sc-1vo1n72-1 jrxHxK"><span data-v-9c516a62="">VIP 1</span></div>
                <span data-v-9c516a62="" class="sc-1p2nuuk-1 csPPf"></span></div>
            <div data-v-9c516a62="" class="sc-q9qagu-14 eOzaio">
                <div data-v-9c516a62="" class="sc-q9qagu-8 dGTvSk"><a data-v-9c516a62="" href="javascript:void(0);"
                                                                      class="title"><span data-v-9c516a62="">Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất...</span></a>
                    <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD"></ul>
                    <span data-v-9c516a62="" class="price">Thỏa thuận</span></div>
                <ul data-v-9c516a62="" class="sc-q9qagu-9 bIsYGD">
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span
                            data-v-9c516a62="">Hôm nay</span></li>
                    <li data-v-9c516a62="" class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span data-v-9c516a62=""
                                                                                                 class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span
                            data-v-9c516a62="">Quận Hoàng Mai, Hà Nội</span></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    </div>
    <div class="call-mobile">
<i class="fa fa-phone"></i>
	<a id="callnowbutton" href="tel:0386972638">0386972638</a>
</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      featuredJobs: [
        { id: 1, title: "Cần tuyển kỹ thuật viên massage - nữ", salary: "Thỏa thuận", location: "Hôm nay | Quận Cầu Giấy", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        { id: 2, title: "Cần tuyển: 10 NAM LAO ĐỘNG PHỔ THÔNG", salary: "Thỏa thuận", location: "Hôm nay | Quận Hoàng Mai", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        { id: 3, title: "Cần tuyển kỹ thuật viên massage - nữ", salary: "Thỏa thuận", location: "Hôm nay | Quận Cầu Giấy", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        { id: 4, title: "Cần tuyển kỹ thuật viên massage - nữ", salary: "Thỏa thuận", location: "Hôm nay | Quận Cầu Giấy", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        // Add more jobs as needed
      ],
      vipJobs: [
        { id: 1, title: "TUYỂN GẤP PHỤ BẾP", salary: "Từ 5.500.000 đ/tháng", location: "Hôm nay | Quận Đống Đa, Hà Nội", image: "image3.jpg" },
        { id: 2, title: "Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van", salary: "9.500.000 - 12.000.000 đ/tháng", location: "Hôm nay | Quận Hoàng Mai, Hà Nội", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        { id: 3, title: "Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van", salary: "9.500.000 - 12.000.000 đ/tháng", location: "Hôm nay | Quận Hoàng Mai, Hà Nội", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        { id: 4, title: "Cty Sao Việt Tuyển dụng Lái xe Thaco, Suzuki Van", salary: "9.500.000 - 12.000.000 đ/tháng", location: "Hôm nay | Quận Hoàng Mai, Hà Nội", image: "https://nghiepdoanlaodongvn.comhttps://nghiepdoanlaodongvn.com/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg" },
        // Add more jobs as needed
      ],
    };
  },
};
</script>
<style scoped>
.success.is-underline:hover,.success.is-outline:hover,.success {
	background-color:#1E73BE;
	color:white !important;
}
.call-mobile {
	background:#ED1C24;
	position:fixed;
	bottom:10px;
	height:40px;
	line-height:40px;
	padding:0 0px 0 0px;
	border-radius:40px;
	color:#fff;
	left:20px;
	z-index:99999;
}
.call-mobile1 {
	position:fixed;
	bottom:52px;
	height:40px;
	line-height:40px;
	padding:0 0px 0 0px;
	border-radius:40px;
	color:#fff;
	left:20px;
	z-index:99999;
}
.call-mobile2 {
	position:fixed;
	bottom:93px;
	height:40px;
	line-height:40px;
	padding:0 0px 0 0px;
	border-radius:40px;
	color:#fff;
	left:20px;
	z-index:99999;
}
.call-mobile i {
	font-size:20px;
	line-height:40px;
	background:#B52026;
	border-radius:100%;
	width:40px;
	height:40px;
	text-align:center;
	float:right;
}
.call-mobile a {
	color:#fff;
	font-size:18px;
	font-weight:bold;
	text-decoration:none;
	margin-right:10px;
	padding-left: 10px;
}
.home-container {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff5722;
  color: white;
  padding: 10px;
}

.logo {
  font-size: 1.5em;
}

.search-bar input {
  padding: 5px;
  font-size: 1em;
}

.content {
  padding: 10px;
}

.featured-jobs,
.vip-jobs {
  margin-bottom: 20px;
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.job-item {
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.job-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.job-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.job-info {
  padding: 10px;
  text-align: center;
}

.job-info h3 {
  font-size: 1.1em;
  margin: 10px 0;
}

.job-info p {
  margin: 5px 0;
  color: #777;
}

.footer {
  background-color: #f1f1f1;
  padding: 10px;
}

.footer nav ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.footer nav ul li {
  cursor: pointer;
}
</style>

