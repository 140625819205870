<template>
 <div data-v-4cf590a6="">
   <link data-v-4cf590a6="" data-v-4282a6d8="" data-v-3bb26555="" href="https://nghiepdoanlaodongvn.com/css/app.99f2331e.css" rel="stylesheet">
   <div data-v-4cf590a6="" data-v-4411676a="" class="convention-hall page">
      <div data-v-4cf590a6="" data-v-4411676a="" class="nav-bar van-nav-bar van-hairline--bottom">
         <div data-v-4cf590a6="" class="van-nav-bar__content">
            <div data-v-4cf590a6="" class="van-nav-bar__title van-ellipsis">Nước ngoài</div>
         </div>
      </div>
      <div data-v-4cf590a6="" data-v-4411676a="" class="container">
         <div data-v-4cf590a6="" data-v-4411676a="" class="row">
            <div data-v-4cf590a6="" data-v-4411676a="" class="left-home col-lg-9 col-md-9 col-sm-9 col-xs-12">
               <div data-v-4cf590a6="" data-v-4411676a="" class="pageone">
                  <div data-v-4cf590a6="" data-v-4411676a="" class="pageone-title" style="margin-bottom: -10px;">
                     <h2 data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="" style="float: left; font-size: 15px; margin-bottom: -10px;">Tuyển Dụng</a></h2>
                     <p data-v-4cf590a6="" data-v-4411676a="" class="line-do"></p>
                  </div>
                  <div data-v-4cf590a6="" data-v-4411676a="" class="pageone-tab">
                     <ul data-v-4cf590a6="" data-v-4411676a="" role="tablist" class="nav nav-tabs">
                        <li data-v-4cf590a6="" data-v-4411676a="" class="active" style="background: rgb(245, 130, 32); height: 32px;"><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" role="tab" data-toggle="tab">Tất cả đơn hàng mới</a></li>
                        <li data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Kỹ sư đi Nhật Bản</a></li>
                        <li data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Đơn hàng cho nam</a></li>
                        <li data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" role="tab" data-toggle="tab" style="height: 32px;">Đơn hàng cho nữ</a></li>
                     </ul>
                     <div data-v-4cf590a6="" data-v-4411676a="" id="style-2" class="tab-content">
                        <div data-v-4cf590a6="" data-v-4411676a="" id="thong-so-ki-thuat" class="tab-pane active">
                           <table data-v-4cf590a6="" data-v-4411676a="" class="donhang-home">
                              <tbody data-v-4cf590a6="" data-v-4411676a="">
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a="">Ngành nghề</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Ngày thi</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Giới tính</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Lương cơ bản</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Số lượng</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="[Phí tổng 105tr] Đơn hàng chế biến thực phẩm cho nữ đi Nhật Bản">Thực phẩm</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">18/11/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">153.054 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">30</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng Tokutei thực phẩm đầu Việt làm sủi cảo tỉnh Gunma">Thực phẩm</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">13/11/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nam/Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">170.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">24</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD">Công xưởng</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">23/10/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nam Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">4000 AUD </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">100</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Nhật Bản tuyển 18 nữ">Thực phẩm</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">26/12/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">176.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">18</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển 30 nữ làm đóng gói công nghiệp tại Nhật Bản làm ở tỉnh Tokyo">Đóng gói</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">22/11/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">157.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">30</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng truyền thống tuyển 100 nữ và 20 nam làm chế biến thủy sản Nhật Bản">Thủy Sản</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">16/12/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nam Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">157.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">120</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng dập kim loại linh kiện ô tô Nissan Nhật Bản tuyển 54 nam">Cơ khí</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">11/11/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nam </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">160.768 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">54</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng chế biến đồ ăn sẵn tại Nhật Bản tuyển 60 nữ làm ở siêu thị">Thực phẩm</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">15/10/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">157.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">60</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Kyoto Nhật Bản tuyển 54 nữ">Thực phẩm</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">23/12/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nữ </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">157.000 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">54</td>
                                 </tr>
                                 <tr data-v-4cf590a6="" data-v-4411676a="">
                                    <td data-v-4cf590a6="" data-v-4411676a=""><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng công xưởng Nhật Bản tuyển 20 nam tại tỉnh Osaka">Phân Loại</a></td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">29/08/2023</td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">Nam </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">167.385 Yên </td>
                                    <td data-v-4cf590a6="" data-v-4411676a="">20</td>
                                 </tr>
                              </tbody>
                           </table>
                           <div data-v-4cf590a6="" data-v-4411676a="" class="xemthemhome"><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xem thêm">Xem thêm</a></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div data-v-4cf590a6="" data-v-4411676a="" class="widget widget-highlight-job pagetow">
                  <h2 data-v-4cf590a6="" data-v-4411676a="" class="widget-title"><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Việc làm ngoài nước mới nhất">Việc làm ngoài nước mới nhất <font data-v-4cf590a6="" data-v-4411676a="" style="float: right; color: red; text-transform: none; font-size: 13px; margin-right: 10px; text-decoration: underline;">Xem thêm</font></a></h2>
                  <div data-v-4cf590a6="" data-v-4411676a="" class="clearfix"></div>
                  <div data-v-4cf590a6="" data-v-4411676a="" class="widget-wrap scrollbar">
                     <div data-v-4cf590a6="" data-v-4411676a="" class="widget-content">
                        <ul data-v-4cf590a6="" data-v-4411676a="">
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>4000 AUD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Úc</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="41 đơn hàng Singapore mới nhất tuyển liên tục">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">41 đơn hàng Singapore mới nhất tuyển liên tục</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>40000 </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Singapore</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>25 AUD/giờ </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Úc</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>1.100 USD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Ba lan</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>1.100 USD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Ba lan</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Thông báo chương trình xuất khẩu lao động Đức được nợ phí">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Thông báo chương trình xuất khẩu lao động Đức được nợ phí</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>80 triệu </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>CHLB Đức</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>2,7 triệu Won </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Hàn Quốc</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>3000 SGD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Singapore</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Thông báo tuyển lao động đi làm việc tại CHLB Đức">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Thông báo tuyển lao động đi làm việc tại CHLB Đức</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>2400 Eur </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Đức</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>36 Triệu đồng </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Philippines</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>85 triệu </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Australia Úc</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>2.800 Euro </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>CHLB Đức</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>80 triệu </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>New Zealand</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển lao động làm xây dựng tại New Zealand">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển lao động làm xây dựng tại New Zealand</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>4000- 6,500 NZD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>New Zealand</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>5000-5500 NZ </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>New Zealand</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>2.200 Euro </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Hungary</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>1.100 USD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Hungary</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>2000USD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Hungary</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>23.800 Đài tệ </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Đài Loan</h4>
                              </a>
                           </li>
                           <li data-v-4cf590a6="" data-v-4411676a="">
                              <a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani">
                                 <h3 data-v-4cf590a6="" data-v-4411676a="">Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani</h3>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Mức lương:</span>1000 USD </h4>
                                 <h4 data-v-4cf590a6="" data-v-4411676a=""><span data-v-4cf590a6="" data-v-4411676a="">Quốc gia:</span>Rumani</h4>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div data-v-4cf590a6="" data-v-4411676a="" class="clear"></div>
               <div data-v-4cf590a6="" data-v-4411676a="" class="xemthemhome" style="margin-top: 80px;"><a data-v-4cf590a6="" data-v-4411676a="" href="javascript:void(0);" title="Xem thêm"></a></div>
            </div>
         </div>
      </div>
      <div data-v-4cf590a6="" data-v-4411676a="" class="call-mobile" style="display: none;"><a data-v-4cf590a6="" data-v-4411676a="" href="tel:0583706666"><img data-v-4cf590a6="" data-v-4411676a="" src="/img/gw/phone_mobile.png" alt="phone" title="phone">0583706666</a></div>
   </div>
</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
