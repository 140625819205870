<template>
  <div class="container page">
    <van-nav-bar title="Thay đổi tên thật" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="save">Lưu</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="name" :readonly="false" label="Tên" placeholder="Nhập tên thật của bạn" />
    </van-cell-group>
    <p>Để đảm bảo an toàn cho tài khoản của bạn, tên thật cần phải giống với tên được liên kết với thẻ ngân hàng</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      userInfo: {}
    };
  },
  methods: {
    back() {
      window.history.back();
    },
    save() {
      if (this.userInfo.name) {
        this.$toast("Vui lòng không lặp lại cài đặt!");
        return true;
      }
      if (this.name === "" || this.name === null || this.name === undefined) {
        this.$toast.fail("Vui lòng nhập tên của bạn!");
        return false;
      }
      this.$http({
        method: 'post',
        data: { name: this.name },
        url: 'user_set_name'
      }).then(res => {
        if (res.code === 200) {
          this.getUserInfo();
          this.$toast(res.msg);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
</style>
